import {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  Fragment,
  SetStateAction,
} from 'react';
import {
  Outlet,
  NavLink,
  useLoaderData,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Chip,
  Divider,
  Modal,
  ModalContent,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  ScrollShadow,
  Select,
  SelectItem,
  Spinner,
  Tab,
  Tabs,
  useDisclosure,
} from '@nextui-org/react';
import { ChevronDownIcon, ChevronUpIcon } from '@nextui-org/shared-icons';

import { motion, AnimatePresence } from 'framer-motion';
import { TRANSITION_VARIANTS } from '@nextui-org/framer-utils';
import { useWindowVirtualizer } from '@tanstack/react-virtual';

import { Search } from 'js-search';
import {
  Contract,
  AlchemyProvider,
  Wallet,
  isError as isEthersError,
} from 'ethers';

import { captureException } from '@sentry/react';

import { Artist, Objekt, cosmoClient } from '../cosmo';
import { useCurrentAccount } from '../credentials';
import { getWalletMnemonic } from '../ramper';
import { polarisClient } from '../polaris';

import { ObjektFilters, ObjektList } from '../components/objekt-list';
import { CosmoNicknameAutocomplete } from '../components/cosmo';
import { ObjektModal } from '../components/objekt-modal';
import { Link } from './root';
import { ErrorIcon, CheckIcon, FilterIcon } from '../icons';
import {
  useMediaQuery,
  useSearchParamState,
  useSearchParamStates,
} from '../hooks';
import { filterObjekts, usePaginatedObjekts } from '../utils';

export async function loader() {
  let [tripleS, artms, objekts] = await Promise.all([
    cosmoClient.getArtist('tripleS'),
    cosmoClient.getArtist('artms'),
    polarisClient.getAllObjekts(),
  ]);
  return {
    objekts,
    artists: [tripleS, artms],
  };
}

export function useOwnedObjekts(address: string) {
  return usePaginatedObjekts(
    startAfter => cosmoClient.getOwnedObjekts(address, startAfter),
    [address],
  );
}

export function useMyOwnedObjekts() {
  let currentAccount = useCurrentAccount();
  let credentials = currentAccount?.credentials;
  return usePaginatedObjekts(
    async startAfter => {
      if (!credentials) {
        return { hasNext: false, total: 0, objekts: [] };
      }
      return await cosmoClient.getMyOwnedObjekts(startAfter);
    },
    [credentials],
  );
}

export function useWantsList(address: string): string[] {
  let [wantsList, setWantsList] = useState<string[]>([]);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      try {
        let { lists } = await polarisClient.getUserDetails(address);
        if (!isCancelled) {
          setWantsList(
            lists.find(list => list.name === 'want')?.collectionIds || [],
          );
        }
      } catch (error) {
        console.error('Failed to fetch wants/haves lists:', error);
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, [address]);
  return wantsList;
}

// TODO: Figure out how to use useWantsList
export function useMyWantsList(): [string[], () => void] {
  let currentAccount = useCurrentAccount();
  let [wantsList, setWantsList] = useState<string[]>([]);
  let [update, setUpdate] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      if (!currentAccount) return;
      try {
        let { lists } = await polarisClient.getUserDetails(
          currentAccount.profile.address,
        );
        if (!isCancelled) {
          setWantsList(
            lists.find(list => list.name === 'want')?.collectionIds || [],
          );
        }
      } catch (error) {
        console.error('Failed to fetch wants/haves lists:', error);
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, [currentAccount, update]);

  function refreshWantsList() {
    setUpdate(!update);
  }
  return [wantsList, refreshWantsList];
}

function ObjektsTab({
  objekts,
  artists,
}: {
  objekts: Objekt[];
  artists: Artist[];
}) {
  let currentAccount = useCurrentAccount();
  let ramperWalletSecrets = currentAccount?.ramperWalletSecrets;

  let ownedObjekts = useMyOwnedObjekts();

  let [myWantsList, reloadMyWantsList] = useMyWantsList();

  let search = useMemo(() => {
    let search = new Search('collectionId');
    search.addIndex('artists');
    search.addIndex('season');
    search.addIndex('class');
    search.addIndex('member');
    search.addIndex('collectionId');
    search.addDocuments(objekts);
    return search;
  }, [objekts]);

  let artistOptions = useMemo(
    () =>
      Array.from(
        new Set(objekts.flatMap(objekt => objekt.artists ?? ['tripleS'])),
      ),
    [objekts],
  );
  let [artistName, setArtistName] = useSearchParamState('artist', 'tripleS');
  let artist = artists.find(artist => artist.name === artistName);

  objekts = useMemo(
    () =>
      objekts
        .filter(objekt => (objekt.artists ?? ['tripleS']).includes(artistName))
        .sort((a, b) => {
          let aIndex =
            artist?.members.findIndex(member => member.name === a.member) ?? -1;
          let bIndex =
            artist?.members.findIndex(member => member.name === b.member) ?? -1;
          if (aIndex === -1) {
            return 1;
          } else if (bIndex === -1) {
            return -1;
          } else {
            return aIndex - bIndex;
          }
        }),
    [objekts, artistName, artist],
  );

  let memberOptions = useMemo(
    () =>
      Array.from(new Set(objekts.map(objekt => objekt.member))).sort((a, b) => {
        let aIndex =
          artist?.members.findIndex(member => member.name === a) ?? -1;
        let bIndex =
          artist?.members.findIndex(member => member.name === b) ?? -1;
        if (aIndex === -1) {
          return 1;
        } else if (bIndex === -1) {
          return -1;
        } else {
          return aIndex - bIndex;
        }
      }),
    [objekts],
  );
  let seasonOptions = useMemo(
    () => Array.from(new Set(objekts.map(objekt => objekt.season))),
    [objekts],
  );
  let classOptions = useMemo(
    () => Array.from(new Set(objekts.map(objekt => objekt.class))),
    [objekts],
  );

  let [members, setMembers] = useSearchParamStates('members');
  let [seasons, setSeasons] = useSearchParamStates('seasons');
  let [classes, setClasses] = useSearchParamStates('classes');
  let [query, setQuery] = useSearchParamState('q', '', { replace: true });
  let [filterOption, setFilterOption] = useState<
    'all' | 'owned' | 'transferable'
  >('all');

  let isWantsList = !!useMatch('/wants');
  let selectedList: 'all' | 'wants' = isWantsList ? 'wants' : 'all';
  let navigate = useNavigate();
  function setSelectedList(selectedList: 'all' | 'wants') {
    switch (selectedList) {
      case 'all':
        navigate('/');
        break;
      case 'wants':
        navigate('/wants');
        break;
    }
  }

  objekts = useMemo(() => {
    objekts = filterObjekts(
      objekts,
      members,
      seasons,
      classes,
      filterOption,
      ownedObjekts,
    );
    if (selectedList === 'wants') {
      objekts = objekts.filter(objekt =>
        myWantsList.includes(objekt.collectionId),
      );
    }
    return objekts;
  }, [
    objekts,
    members,
    seasons,
    classes,
    filterOption,
    ownedObjekts,
    selectedList,
    myWantsList,
  ]);

  // query changes frequently, so should be memoized separately.
  objekts = useMemo(() => {
    if (query) {
      objekts = (search.search(query) as Objekt[])
        .filter(objekt => objekts.includes(objekt))
        .sort((a, b) => {
          let aIndex =
            artist?.members.findIndex(member => member.name === a.member) ?? -1;
          let bIndex =
            artist?.members.findIndex(member => member.name === b.member) ?? -1;
          if (aIndex === -1) {
            return 1;
          } else if (bIndex === -1) {
            return -1;
          } else {
            return aIndex - bIndex;
          }
        });
    }
    return objekts;
  }, [objekts, search, query]);

  let [selectedObjekts, setSelectedObjekts_] = useState<Objekt[]>([]);

  let [error, setError] = useState('');
  let [isLoading, setIsLoading] = useState(false);

  let [recipientNickname, setRecipientNickname_] = useState('');
  function setRecipientNickname(newRecipientNickname: SetStateAction<string>) {
    setRecipientNickname_(newRecipientNickname);
    setError('');
  }

  let [txStates, setTxStates] = useState<
    { status: 'initial' | 'sending' | 'success' | 'error'; error: string }[]
  >([]);

  function setSelectedObjekts(newSelectedObjekts: SetStateAction<Objekt[]>) {
    if (isLoading) {
      return;
    }
    if (typeof newSelectedObjekts === 'function') {
      newSelectedObjekts = newSelectedObjekts(selectedObjekts);
    }
    setSelectedObjekts_(newSelectedObjekts);
    setTxStates(
      newSelectedObjekts.map(() => ({ status: 'initial', error: '' })),
    );
  }

  async function onSendButtonPress() {
    if (!ramperWalletSecrets) {
      return;
    }
    try {
      setIsLoading(true);
      setError('');
      let provider = new AlchemyProvider(
        'matic',
        'jKHL8FBDC9OR14KUb_n-J0_5KoF9hjDo',
      );
      let feeData = await provider.getFeeData();

      let recipientProfile =
        await cosmoClient.getProfileByNickname(recipientNickname);

      let contractAddresses = {
        artms: '0x0fB69F54bA90f17578a59823E09e5a1f8F3FA200',
        tripleS: '0xA4B37bE40F7b231Ee9574c4b16b7DDb7EAcDC99B',
      };
      let contractAbi = [
        'function transferFrom(address from, address to, uint256 tokenId) public',
      ];
      let contracts = {
        artms: new Contract(contractAddresses['artms'], contractAbi, provider),
        tripleS: new Contract(
          contractAddresses['tripleS'],
          contractAbi,
          provider,
        ),
      };
      let { version, dek, encryptedKey } = ramperWalletSecrets;
      let wallet = Wallet.fromPhrase(
        await getWalletMnemonic(version, dek, encryptedKey),
        provider,
      );

      let senderAddress = wallet.address;
      let recipientAddress = recipientProfile.address;

      let nonce = await wallet.getNonce();
      let hasError = false;

      for (let i = 0; i < selectedObjekts.length; i += 8) {
        let selectedObjektsBatch = selectedObjekts.slice(i, i + 8);
        await Promise.all(
          selectedObjektsBatch.map(async (selectedObjekt, j) => {
            let index = i + j;
            try {
              let artist = (selectedObjekt.artists ?? ['tripleS'])[0] as
                | 'artms'
                | 'tripleS';
              let tokenId = selectedObjekt.tokenId;
              let contract = contracts[artist];
              let contractAddress = contractAddresses[artist];
              let txData = contract.interface.encodeFunctionData(
                'transferFrom',
                [senderAddress, recipientAddress, tokenId],
              );
              let tx = {
                nonce: nonce++,
                // gasLimit: 300000,
                gasLimit: await wallet.estimateGas({
                  to: contractAddress,
                  data: txData,
                }),
                maxFeePerGas: (feeData.maxFeePerGas! * 160n) / 100n,
                maxPriorityFeePerGas:
                  (feeData.maxPriorityFeePerGas! * 160n) / 100n,
                to: contractAddress,
                data: txData,
              };
              setTxStates(txStates => [
                ...txStates.slice(0, index),
                { status: 'sending', error: '' },
                ...txStates.slice(index + 1),
              ]);
              let txResult = await wallet.sendTransaction(tx);
              let txReceipt = await txResult.wait();
              console.log(txReceipt);
              setTxStates(txStates => [
                ...txStates.slice(0, index),
                { status: 'success', error: '' },
                ...txStates.slice(index + 1),
              ]);
            } catch (error) {
              // .catch(error =>
              hasError = true;
              console.error(error);
              captureException(error);
              console.dir(error);
              let message: string;
              if (isEthersError(error, 'CALL_EXCEPTION')) {
                message = error.reason ?? 'A blockchain error has occurred.';
                if (
                  error.reason ===
                  'ERC721: transfer caller is not owner nor approved'
                ) {
                  message = 'You have already sent this objekt.';
                }
              } else if (isEthersError(error, 'TRANSACTION_REPLACED')) {
                message =
                  'Transaction was replaced\nThis usually means you have sent other objekts in a different method.';
              } else if (error instanceof Error) {
                message = error.message;
              } else {
                message = 'An unknown error has occurred.';
              }
              setTxStates(txStates => [
                ...txStates.slice(0, index),
                { status: 'error', error: message },
                ...txStates.slice(index + 1),
              ]);
            }
          }),
        );
      }
      if (!hasError) {
        setSelectedObjekts([]);
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  let [isCardOpen, setIsCardOpen] = useState(true);
  let [selectedObjektForModal, setSelectedObjektForModal] =
    useState<Objekt | null>(null);
  let {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onOpenChange: onModalOpenChange,
  } = useDisclosure();

  function onObjektPress(objekt: Objekt) {
    setSelectedObjektForModal(objekt);
    onModalOpen();
  }

  let isMobile = !useMediaQuery('(min-width: 640px)');

  let [isFiltersOpen, setIsFiltersOpen] = useState(false);
  let isFiltersActive =
    members.length || seasons.length || classes.length || query;

  return (
    <div className='flex flex-col gap-4'>
      <div>
        {isMobile ? (
          <Chip
            as='button'
            color='primary'
            variant={isFiltersActive || isFiltersOpen ? 'solid' : 'flat'}
            startContent={<FilterIcon className='w-4 h-4 ml-1' />}
            onClick={() => setIsFiltersOpen(!isFiltersOpen)}
          >
            Filters
          </Chip>
        ) : null}
        <AnimatePresence>
          {!isMobile || (isFiltersOpen && isMobile) ? (
            <motion.div
              variants={TRANSITION_VARIANTS.collapse}
              animate='enter'
              exit='exit'
              initial='exit'
            >
              <div className={isMobile ? 'pt-4' : ''}>
                <ObjektFilters
                  artist={artistName}
                  members={members}
                  seasons={seasons}
                  classes={classes}
                  artistOptions={artistOptions}
                  memberOptions={memberOptions}
                  seasonOptions={seasonOptions}
                  classOptions={classOptions}
                  setArtist={setArtistName}
                  setMembers={setMembers}
                  setSeasons={setSeasons}
                  setClasses={setClasses}
                  query={query}
                  setQuery={setQuery}
                />
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
      <div className='flex flex-col w-full sm:flex-row gap-4'>
        {selectedList === 'all' ? (
          <RadioGroup
            label='Display options'
            orientation='horizontal'
            value={filterOption}
            onValueChange={value =>
              setFilterOption(value as 'all' | 'owned' | 'transferable')
            }
            className='grow order-2 sm:order-1'
          >
            <Radio value='all'>All</Radio>
            <Radio value='owned'>Owned</Radio>
            <Radio value='transferable'>Sendable</Radio>
          </RadioGroup>
        ) : (
          <div className='flex flex-row gap-2 grow order-2 sm:order-1' />
        )}
        <Select
          label='Display List'
          selectedKeys={[selectedList]}
          onSelectionChange={selectedKeys =>
            setSelectedList(Array.from(selectedKeys as Set<'all' | 'wants'>)[0])
          }
          className='w-[auto] sm:max-w-xs order-1 sm:order-2 flex-1'
        >
          <SelectItem key='all'>All Objekts</SelectItem>
          <SelectItem key='wants'>My Wants</SelectItem>
        </Select>
      </div>
      <div className='text-medium font-medium'>
        {query && `Search results for: ${query}`}
      </div>
      <Modal size='3xl' isOpen={isModalOpen} onOpenChange={onModalOpenChange}>
        <ModalContent>
          {onClose => (
            <ObjektModal
              objekt={selectedObjektForModal!}
              onClose={onClose}
              ownedObjekts={ownedObjekts
                .filter(
                  objekt =>
                    objekt.collectionId ===
                    selectedObjektForModal!.collectionId,
                )
                // toSorted is from ES2023
                // .toSorted((a, b) => a.objektNo - b.objektNo)
                // since .filter returns a new array, an in-place sort works.
                .sort((a, b) => a.objektNo - b.objektNo)}
              selectedObjekts={selectedObjekts}
              setSelectedObjekts={setSelectedObjekts}
            />
          )}
        </ModalContent>
      </Modal>
      {objekts.length === 0 ? (
        <div className='flex flex-col gap-2 items-center'>
          <div className='text-small font-medium text-foreground-500'>
            {selectedList === 'wants'
              ? 'Mark any objekt as Want to share your Want list.'
              : 'No objekt matches your query.'}
          </div>
          {selectedList === 'wants' && (
            <img
              className='max-w-96'
              src={
                new URL('../resources/add-to-list.png', import.meta.url).href
              }
            />
          )}
        </div>
      ) : null}
      <ObjektList
        objekts={objekts}
        ownedObjekts={ownedObjekts}
        selectedObjekts={selectedObjekts}
        setSelectedObjekts={setSelectedObjekts}
        onObjektPress={onObjektPress}
        wantsList={myWantsList}
        onListChange={reloadMyWantsList}
      />
      <AnimatePresence>
        {selectedObjekts.length > 0 && (
          <motion.div
            className='fixed inset-x-6 bottom-4 max-w-lg mx-auto'
            variants={TRANSITION_VARIANTS.scaleSpringOpacity}
            initial='initial'
            animate='enter'
            exit='exit'
          >
            <Card className='max-h-[60vh]'>
              <CardHeader>
                <div className='flex-1 font-bold'>
                  {selectedObjekts.length} Selected Objekt
                  {selectedObjekts.length > 1 ? 's' : ''}
                </div>
                <button
                  className='appearance-none select-none p-2 text-foreground-500 rounded-full hover:bg-default-100 active:bg-default-200 tap-highlight-transparent data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2'
                  onClick={() => setIsCardOpen(!isCardOpen)}
                >
                  {isCardOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
                </button>
              </CardHeader>
              {isCardOpen ? (
                <>
                  <Divider />
                  <ScrollShadow>
                    <CardBody as={motion.div} layout>
                      <AnimatePresence initial={false}>
                        {selectedObjekts.map((objekt, i) => (
                          <motion.div
                            key={objekt.tokenId}
                            layout
                            variants={TRANSITION_VARIANTS.collapse}
                            animate='enter'
                            exit='exit'
                            initial='exit'
                          >
                            <div className='flex h-8'>
                              <Checkbox
                                className='max-w-none flex-1'
                                isSelected={selectedObjekts.includes(objekt)}
                                onValueChange={isSelected => {
                                  if (isSelected) {
                                    setSelectedObjekts([
                                      ...selectedObjekts,
                                      objekt,
                                    ]);
                                  } else {
                                    setSelectedObjekts(
                                      selectedObjekts.filter(
                                        selectedObjekt =>
                                          selectedObjekt !== objekt,
                                      ),
                                    );
                                  }
                                }}
                              >
                                {objekt.collectionId} #{objekt.objektNo}
                              </Checkbox>
                              {txStates[i].status === 'sending' && (
                                <Spinner size='sm' className='w-8 h-8' />
                              )}
                              {txStates[i].status === 'error' && (
                                <Popover>
                                  <PopoverTrigger>
                                    <Button
                                      isIconOnly
                                      size='sm'
                                      variant='light'
                                      color='warning'
                                      aria-label='View error'
                                    >
                                      <ErrorIcon className='w-6 h-6' />
                                    </Button>
                                  </PopoverTrigger>
                                  <PopoverContent>
                                    <p className='max-w-[20rem] text-danger break-words'>
                                      {txStates[i].error
                                        .split('\n')
                                        .map((line, index) => (
                                          <Fragment key={index}>
                                            {index !== 0 && <br />}
                                            {line}
                                          </Fragment>
                                        ))}
                                    </p>
                                  </PopoverContent>
                                </Popover>
                              )}
                              {txStates[i].status === 'success' && (
                                <CheckIcon className='text-success w-8 h-8 p-1' />
                              )}
                            </div>
                          </motion.div>
                        ))}
                      </AnimatePresence>
                    </CardBody>
                  </ScrollShadow>
                  <Divider />
                  <CardBody className='shrink-0'>
                    <CosmoNicknameAutocomplete
                      label='COSMO ID'
                      placeholder='Recipient COSMO ID'
                      nickname={recipientNickname}
                      setNickname={setRecipientNickname}
                      isInvalid={!!error}
                      errorMessage={error}
                    />
                  </CardBody>
                  <Divider />
                  <CardFooter className='shrink-0'>
                    <Button
                      color='primary'
                      onClick={onSendButtonPress}
                      isLoading={isLoading}
                    >
                      Send
                    </Button>
                  </CardFooter>
                </>
              ) : null}
            </Card>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export function TradesTab({ address }: { address: string }) {
  let [transfers, setTransfers] = useState<
    {
      from: string;
      to: string;
      blockNumber: number;
      objekt: Objekt;
    }[]
  >([]);
  let [nicknames, setNicknames] = useState<{
    [address: string]: string;
  }>({});
  let [timestamps, setTimestamps] = useState<{
    [blockNumber: number]: number;
  }>({});

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      let { transfers } = await polarisClient.getUserDetails(address);
      let addresses = new Set<string>();
      let blockNumbers = new Set<number>();
      for (let transfer of transfers) {
        if (transfer.from !== '0x0000000000000000000000000000000000000000') {
          addresses.add(transfer.from);
        }
        addresses.add(transfer.to);
        blockNumbers.add(transfer.blockNumber);
      }
      await Promise.all([
        getNicknames(Array.from(addresses)).then(nicknames => {
          if (!isCancelled) {
            setTransfers(transfers);
            setNicknames(nicknames);
          }
        }),
        getTimestamps(Array.from(blockNumbers)).then(timestamps => {
          if (!isCancelled) {
            setTransfers(transfers);
            setTimestamps(timestamps);
          }
        }),
      ]);
      // if (!isCancelled) {
      //   setTransfers(transfers);
      //   setNicknames(nicknames);
      //   setTimestamps(timestamps);
      // }
    })();
    return () => void (isCancelled = true);
  }, [address]);

  let parentRef = useRef<HTMLDivElement>(null);
  let parentOffsetRef = useRef(0);
  useLayoutEffect(() => {
    parentOffsetRef.current = parentRef.current?.offsetTop ?? 0;
  }, []);

  let virtualizer = useWindowVirtualizer({
    count: transfers.length,
    estimateSize: () => 40,
    scrollMargin: parentOffsetRef.current,
  });
  let virtualItems = virtualizer.getVirtualItems();

  return (
    <div ref={parentRef}>
      <div style={{ height: virtualizer.getTotalSize() }} className='relative'>
        <div
          className='absolute top-0 inset-x-0'
          style={{
            transform: `translateY(${
              (virtualItems[0]?.start ?? 0) - virtualizer.options.scrollMargin
            }px)`,
          }}
        >
          {virtualItems.map(item => {
            let transfer = transfers[item.index];
            return (
              <div
                key={item.index}
                data-index={item.index}
                ref={virtualizer.measureElement}
              >
                <div className='flex flex-col gap-1 md:flex-row md:gap-4 mx-auto py-2 items-center justify-center text-center'>
                  <div className='md:basis-48'>
                    {timestamps[transfer.blockNumber] ? (
                      new Date(
                        timestamps[transfer.blockNumber] * 1000,
                      ).toLocaleString('en-US')
                    ) : (
                      <Spinner size='sm' />
                    )}
                  </div>
                  <Divider
                    orientation='vertical'
                    className='hidden md:block h-auto self-stretch'
                  />
                  <div className='md:basis-64'>
                    {transfer.objekt.collectionId} #{transfer.objekt.objektNo}
                  </div>
                  <Divider
                    orientation='vertical'
                    className='hidden md:block h-auto self-stretch'
                  />
                  <div className='flex gap-2 md:basis-48 items-center justify-center'>
                    {transfer.to === address ? (
                      <Chip size='sm' color='primary'>
                        From
                      </Chip>
                    ) : (
                      <Chip size='sm' color='secondary'>
                        To
                      </Chip>
                    )}
                    {transfer.to === address ? (
                      transfer.from ===
                      '0x0000000000000000000000000000000000000000' ? (
                        'COSMO'
                      ) : nicknames[transfer.from] ? (
                        <Link
                          className='inline'
                          to={`/@${nicknames[transfer.from]}`}
                        >
                          {nicknames[transfer.from]}
                        </Link>
                      ) : (
                        <Link className='inline' to={`/${transfer.from}`}>
                          {transfer.from}
                        </Link>
                      )
                    ) : nicknames[transfer.to] ? (
                      <Link
                        className='inline'
                        to={`/@${nicknames[transfer.to]}`}
                      >
                        {nicknames[transfer.to]}
                      </Link>
                    ) : (
                      <Link className='inline' to={`/${transfer.to}`}>
                        {transfer.to}
                      </Link>
                    )}
                  </div>
                </div>
                <Divider className='md:max-w-[46rem] mx-auto' />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export async function getNicknames(
  addresses: string[],
): Promise<{ [address: string]: string }> {
  if (addresses.length == 0) {
    return {};
  }
  let promises: Promise<[string, string][]>[] = [];
  for (let i = 0; i < addresses.length; i += 150) {
    let addressesBatch = addresses.slice(i, i + 150);
    promises.push(
      (async () => {
        let response = await fetch(
          `https://cache.nova.gd/user/v1/by-address/${addressesBatch.join(
            ',',
          )}`,
        );
        let json = (await response.json()) as {
          nickname: string;
          address: string;
          profileImageUrl: string;
        }[];
        return json.map(({ address, nickname }) => [address, nickname]);
      })(),
    );
  }
  let entries = (await Promise.all(promises)).flat();
  return Object.fromEntries(entries);
}

let provider = new AlchemyProvider('matic', 'jKHL8FBDC9OR14KUb_n-J0_5KoF9hjDo');

export async function getTimestamps(
  blockNumbers: number[],
): Promise<{ [blockNumber: number]: number }> {
  let entries = (
    await Promise.all(
      Array.from(blockNumbers).map(async blockNumber => [
        blockNumber,
        (await provider.getBlock(blockNumber))?.timestamp,
      ]),
    )
  ).filter(([, timestamp]) => !!timestamp);
  return Object.fromEntries(entries);
}

export function ObjektsTabRoute() {
  let { objekts, artists } = useLoaderData() as {
    objekts: Objekt[];
    artists: Artist[];
  };
  return <ObjektsTab objekts={objekts} artists={artists} />;
}

export function TradesTabRoute() {
  let currentAccount = useCurrentAccount();
  if (!currentAccount) {
    return null;
  }
  return <TradesTab address={currentAccount.profile.address} />;
}

export default function Objekts() {
  let currentAccount = useCurrentAccount();
  let isTradesTab = !!useMatch('/trades');

  return (
    <div className='max-w-5xl mx-auto px-6 py-2 flex flex-col gap-4'>
      <Tabs
        classNames={{ panel: 'p-0' }}
        disabledKeys={currentAccount ? [] : ['trades']}
        selectedKey={isTradesTab ? 'trades' : 'objekts'}
      >
        <Tab
          key='objekts'
          title={
            <NavLink to=''>
              {currentAccount ? 'My Objekts' : 'All Objekts'}
            </NavLink>
          }
        >
          <Outlet />
        </Tab>
        <Tab
          key='trades'
          title={
            currentAccount ? (
              <NavLink to='trades'>My Trades</NavLink>
            ) : (
              'My Trades'
            )
          }
        >
          <Outlet />
        </Tab>
      </Tabs>
    </div>
  );
}
