import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import {
  getAuth,
  setPersistence,
  inMemoryPersistence,
  signInWithCustomToken,
} from 'firebase/auth';
import {
  KMSClient,
  DecryptCommand,
  DecryptCommandInput,
} from '@aws-sdk/client-kms';
import {
  fromCognitoIdentityPool,
  fromTemporaryCredentials,
} from '@aws-sdk/credential-providers';

let firebaseConfig = {
  apiKey: 'AIzaSyCsJq-bilsQp7GrVSX3LIUVX6EcAdCcuTs',
  databaseURL: 'https://ramper-prod-default-rtdb.firebaseio.com',
  projectId: 'ramper-prod',
  storageBucket: 'ramper-prod.appspot.com',
  appId: '1:897776340259:web:9d8fab89bcccd20fcfd096',
};

export type RamperWalletSecrets = {
  version: number;
  dek: string;
  encryptedKey: string;
};

export async function getRamperWalletSecrets(
  socialLoginUserId: string,
  customToken: string,
): Promise<RamperWalletSecrets> {
  let app = initializeApp(firebaseConfig);
  let firestore = getFirestore(app);
  let auth = getAuth(app);
  setPersistence(auth, inMemoryPersistence);

  await signInWithCustomToken(auth, customToken);
  let currentUser = auth.currentUser!;

  let idToken = await currentUser.getIdToken();
  let creationTime = currentUser.metadata.creationTime ?? '';
  let providerUID = currentUser.providerData[0].uid;

  let walletKeysSnapshot = await getDocs(
    collection(
      firestore,
      'users',
      socialLoginUserId,
      'walletList',
      'default',
      'walletkey',
    ),
  );
  let walletKeyData = walletKeysSnapshot.docs[0].data();
  let encryptedKey: string = walletKeyData.encryptedKey;
  let fiURI: string =
    walletKeyData.fiUri ?? 'us-east-1:662b2574-190f-4e2a-be17-1ae5ee8d88a4';
  let fiRegion: string = fiURI.split(':')[0];
  let version: number = walletKeyData.version;
  let encryptedDEK: Uint8Array = walletKeyData.dek.toUint8Array();

  let masterCredentials = await fromCognitoIdentityPool({
    identityPoolId: fiURI,
    logins: {
      'securetoken.google.com/ramper-prod': idToken,
    },
    clientConfig: {
      region: fiRegion,
    },
  })();
  let identityId = masterCredentials.identityId;
  let pool = masterCredentials.identityId.charAt(
    masterCredentials.identityId.length - 1,
  );
  let credentials = await fromTemporaryCredentials({
    masterCredentials,
    params: {
      RoleArn: `arn:aws:iam::299151497192:role/cognito-pool-${pool}`,
      DurationSeconds: 900,
    },
    clientConfig: {
      region: fiRegion,
    },
  })();

  let input: DecryptCommandInput;
  let region: string;

  if (version === 3.1) {
    region = 'us-east-2';
    input = {
      CiphertextBlob: encryptedDEK,
      EncryptionContext: { provider: providerUID, ct: creationTime },
      KeyId: 'arn:aws:kms:us-east-2:299151497192:alias/kek',
      EncryptionAlgorithm: 'SYMMETRIC_DEFAULT',
    };
  } else if (version < 6) {
    region = 'us-east-1';
    input = {
      CiphertextBlob: encryptedDEK,
      KeyId: `arn:aws:kms:us-east-1:299151497192:alias/kek00${pool}`,
      EncryptionAlgorithm: 'RSAES_OAEP_SHA_256',
    };
  } else if (version < 7) {
    region = 'ap-northeast-2';
    input = {
      CiphertextBlob: encryptedDEK,
      KeyId: `arn:aws:kms:ap-northeast-2:299151497192:alias/mkek00${pool}`,
      EncryptionAlgorithm: 'RSAES_OAEP_SHA_256',
    };
  } else if (version === 7) {
    region = 'us-east-2';
    input = {
      CiphertextBlob: encryptedDEK,
      EncryptionContext: {
        provider: providerUID,
        ct: creationTime,
        cred: identityId,
      },
      KeyId: `arn:aws:kms:us-east-2:299151497192:alias/skek00${pool}`,
      EncryptionAlgorithm: 'SYMMETRIC_DEFAULT',
    };
  } else {
    // ERROR: unknown version
    throw new Error('Unknown ramper wallet version');
  }

  let client = new KMSClient({
    region,
    credentials,
  });

  let command = new DecryptCommand(input);
  let response = await client.send(command);
  let dek = new TextDecoder().decode(response.Plaintext);

  return { version, dek, encryptedKey };
}

function base64ToBytes(base64: string): Uint8Array {
  // @ts-ignore
  return Uint8Array.from(window.atob(base64), m => m.codePointAt(0));
}

function hexToBytes(hex: string): Uint8Array {
  return Uint8Array.from(hex.match(/.{1,2}/g)!.map(b => parseInt(b, 16)));
}

export async function getWalletMnemonic(
  version: number,
  dek: string,
  encryptedKey: string,
) {
  if (version === 3.1) {
    let iterations = 1e3;

    let keyMaterial = await crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(dek),
      'PBKDF2',
      false,
      ['deriveKey'],
    );

    let salt = hexToBytes(encryptedKey.substring(0, 32));
    let iv = hexToBytes(encryptedKey.substring(32, 64));
    let data = base64ToBytes(encryptedKey.substring(64));

    let key = await crypto.subtle.deriveKey(
      { name: 'PBKDF2', salt, iterations, hash: 'SHA-1' },
      keyMaterial,
      { name: 'AES-CBC', length: 256 },
      false,
      ['decrypt'],
    );

    let mnemonic = new TextDecoder().decode(
      await crypto.subtle.decrypt({ name: 'AES-CBC', iv }, key, data),
    );
    return mnemonic;
  } else if (version <= 7) {
    let data = base64ToBytes(encryptedKey);
    let key = await crypto.subtle.importKey(
      'raw',
      hexToBytes(dek),
      'AES-CBC',
      false,
      ['decrypt'],
    );
    let iv = Uint8Array.from(new Array(16).fill(0));
    let mnemonic = new TextDecoder().decode(
      hexToBytes(
        new TextDecoder().decode(
          await crypto.subtle.decrypt({ name: 'AES-CBC', iv }, key, data),
        ),
      ),
    );
    return mnemonic;
  } else {
    throw new Error('Unknown ramper wallet version');
  }
}
