import { useState, useEffect, useCallback, useRef } from 'react';
import {
  AlchemyProvider,
  Contract,
  ContractEventPayload,
  EventLog,
} from 'ethers';

import * as Plot from '@observablehq/plot';
import {
  Card,
  CardBody,
  CardHeader,
  Divider,
  ScrollShadow,
  Spinner,
  Switch,
} from '@nextui-org/react';
import { ChevronDownIcon, ChevronUpIcon } from '@nextui-org/shared-icons';
import { motion, AnimatePresence } from 'framer-motion';
import { TRANSITION_VARIANTS } from '@nextui-org/framer-utils';

import { cosmoClient } from '../cosmo';
import { Link } from './root';

let provider = new AlchemyProvider('matic', 'jKHL8FBDC9OR14KUb_n-J0_5KoF9hjDo');
let contractAddress = '0xc3E5ad11aE2F00c740E74B81f134426A3331D950';
let contractAbi = [
  'event Finalized(uint256 indexed pollId, uint256 burned)',
  'event Initialized(uint8 version)',
  'event PollCreated(uint256 pollId)',
  'event Revealed(uint256 indexed pollId, uint256 revealedVotes, uint256 remainingVotes)',
  'event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)',
  'event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)',
  'event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)',
  'event Voted(uint256 indexed pollId, uint256 voteIndex, address voter, uint256 comoAmount, bytes32 hash)',
  'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
  'function OPERATOR_ROLE() view returns (bytes32)',
  'function candidates(uint256 pollId) view returns (string[])',
  'function comoContract() view returns (address)',
  'function createPoll(string title_, string[] candidates_, uint256 startAt_, uint256 due_, uint256 minimumCOMO_)',
  'function finalize(uint256 pollId)',
  'function getRoleAdmin(bytes32 role) view returns (bytes32)',
  'function grantRole(bytes32 role, address account)',
  'function hasRole(bytes32 role, address account) view returns (bool)',
  'function hashes(uint256, bytes32) view returns (bool)',
  'function initialize(address voteSignerAddress_, address comoAddress_)',
  'function isInProgress(uint256 pollId) view returns (bool)',
  'function isRevealedVote(uint256, uint256) view returns (bool)',
  'function pollResult(uint256 pollId) view returns (tuple(string candidate, uint256 votes)[])',
  'function polls(uint256) view returns (string title, uint256 startAt, uint256 due, uint256 minimumCOMO, uint256 totalVotedCOMO, uint256 revealedVotes, bool finalized)',
  'function remainingVotes(uint256 pollId) view returns (uint256)',
  'function renounceRole(bytes32 role, address account)',
  'function reset(uint256 pollId, uint256 missingOffset, tuple(uint256 comoAmount, bytes32 hash)[] missingCommitData)',
  'function reveal(uint256 pollId, tuple(uint256 votedCandidateId, bytes32 salt)[] data, uint256 offset)',
  'function revokeRole(bytes32 role, address account)',
  'function setVoteSignerAddress(address addr)',
  'function supportsInterface(bytes4 interfaceId) view returns (bool)',
  'function tokensReceived(address operator, address from, address to, uint256 amount, bytes userData, bytes operatorData)',
  'function totalVotes(uint256 pollId) view returns (uint256)',
  'function userVoteResult(uint256 pollId, address voter) view returns (tuple(string candidate, uint256 votes)[])',
  'function userVoteResults(uint256, address, uint256) view returns (uint256)',
  'function voteSignerAddress() view returns (address)',
  'function voters(uint256, uint256) view returns (address)',
  'function votes(uint256 pollId) view returns (tuple(uint256 comoAmount, bytes32 hash)[])',
  'function votesPerCandidates(uint256 pollId) view returns (uint256[])',
];
let contract = new Contract(contractAddress, contractAbi, provider);

type VoteLog = {
  pollId: bigint;
  voteIndex: bigint;
  voter: string;
  comoAmount: bigint;
  hash: string;
};

// void async function () {
//   console.log(await contract.votesPerCandidates(93n));
// }();

export default function Gravity() {
  let [voteLogs, setVoteLogs] = useState<VoteLog[]>([]);
  let [nicknames, setNicknames] = useState<{ [address: string]: string }>({});
  // let [gravityTitle, setGravityTitle] = useState<string>('');
  let [plotSize, setPlotSize] = useState({ width: 0, height: 0 });
  let plotContainerRef = useRef<HTMLDivElement | null>(null);
  let firstBlockNumberRef = useRef<number | null>(null);

  let listener = useCallback(
    (
      pollId: bigint,
      voteIndex: bigint,
      voter: string,
      comoAmount: bigint,
      hash: string,
      event: ContractEventPayload,
    ) => {
      if (!firstBlockNumberRef.current) {
        firstBlockNumberRef.current = event.log.blockNumber;
      }
      setVoteLogs(voteLogs => [
        ...voteLogs,
        { pollId, voteIndex, voter, comoAmount, hash },
      ]);
      if (!nicknames[voter]) {
        fetchNicknames([voter]).then(newNicknames =>
          setNicknames(nicknames => ({ ...nicknames, ...newNicknames })),
        );
      }
    },
    [nicknames],
  );

  useEffect(() => {
    contract.addListener('Voted', listener);
    return () => void contract.removeListener('Voted', listener);
  }, [listener]);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      let gravities = await cosmoClient.getGravities();
      let lastGravity = gravities.ongoing.length
        ? gravities.ongoing[0]
        : gravities.past[0];
      // setGravityTitle(lastGravity.title);
      let pollCreatedEventLogs = (await contract.queryFilter(
        'PollCreated',
      )) as EventLog[];
      let pollCreatedEvent = pollCreatedEventLogs.find(
        ({ args: [pollId] }) => pollId == lastGravity.polls[0].pollIdOnChain,
      );
      let startBlockNumber = pollCreatedEvent!.blockNumber;
      let lastBlockNumber = await provider.getBlockNumber();
      let queriedEventLogsPromises: Promise<EventLog[]>[] = [];
      let currentBlockNumber = startBlockNumber;
      while (currentBlockNumber < lastBlockNumber) {
        let endBlockNumber = Math.min(
          lastBlockNumber - 1,
          currentBlockNumber + 1999,
        );
        // console.log('queryFilter', startBlockNumber, endBlockNumber);
        queriedEventLogsPromises.push(
          contract.queryFilter(
            'Voted',
            currentBlockNumber,
            endBlockNumber,
          ) as Promise<EventLog[]>,
        );
        currentBlockNumber += 2000;
      }
      queriedEventLogsPromises.push(
        contract.queryFilter('Voted', lastBlockNumber) as Promise<EventLog[]>,
      );
      let queriedEventLogs = (
        await Promise.all(queriedEventLogsPromises)
      ).flat();
      let firstBlockNumber = firstBlockNumberRef.current;
      if (firstBlockNumber) {
        queriedEventLogs = queriedEventLogs.filter(
          eventLog => eventLog.blockNumber < firstBlockNumber!,
        );
      }
      let queriedVoteLogs = queriedEventLogs.map(
        ({ args: [pollId, voteIndex, voter, comoAmount, hash] }) => ({
          pollId,
          voteIndex,
          voter,
          comoAmount,
          hash,
        }),
      );
      if (!isCancelled) {
        setVoteLogs(voteLogs => [...queriedVoteLogs, ...voteLogs]);
      }
      let addresses = [
        ...new Set(queriedEventLogs.map(eventLog => eventLog.args[2])),
      ];
      let newNicknames = await fetchNicknames(addresses);
      if (!isCancelled) {
        setNicknames(nicknames => ({ ...nicknames, ...newNicknames }));
      }
    })();
    return () => {
      isCancelled = true;
      setVoteLogs([]);
    };
  }, []);

  let [isCardOpen, setIsCardOpen] = useState(true);
  let [shouldAutoScroll, setShouldAutoScroll] = useState(true);

  let scrollContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (scrollContainerRef.current && shouldAutoScroll) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  }, [shouldAutoScroll, voteLogs, isCardOpen]);

  useEffect(() => {
    if (!plotContainerRef.current || voteLogs.length === 0) {
      return;
    }
    let rect = plotContainerRef.current.getBoundingClientRect();
    setPlotSize({
      width: rect.width,
      height: rect.height,
    });
    let resizeObserver = new ResizeObserver(entries => {
      if (entries[0].contentRect.width && entries[0].contentRect.height) {
        setPlotSize({
          width: entries[0].contentRect.width,
          height: entries[0].contentRect.height,
        });
      }
    });
    resizeObserver.observe(plotContainerRef.current);
    return () => resizeObserver.disconnect();
  }, [plotContainerRef.current, voteLogs]);

  useEffect(() => {
    if (
      !plotContainerRef.current ||
      voteLogs.length === 0 ||
      plotSize.width === 0 ||
      plotSize.height === 0
    ) {
      return;
    }
    let accumulatedComoAmount = 0n;
    let transformedData = voteLogs.map(voteLog => {
      accumulatedComoAmount += voteLog.comoAmount;
      return {
        voteIndex: Number(voteLog.voteIndex),
        accumulatedComoAmount: Number(
          accumulatedComoAmount / 1000000000000000000n,
        ),
        voter: nicknames[voteLog.voter] || voteLog.voter,
        comoAmount: Number(voteLog.comoAmount / 1000000000000000000n),
      };
    });
    let plot = Plot.plot({
      width: plotSize.width,
      height: plotSize.height,
      x: { grid: true, label: 'Vote Index' },
      y: { grid: true, label: 'COMO' },
      marks: [
        Plot.lineY(transformedData, {
          x: 'voteIndex',
          y: 'accumulatedComoAmount',
        }),
        Plot.areaY(transformedData, {
          x: 'voteIndex',
          y: 'accumulatedComoAmount',
          fillOpacity: 0.2,
        }),
        Plot.crosshairX(transformedData, {
          x: 'voteIndex',
          y: 'accumulatedComoAmount',
        }),
        Plot.dot(
          transformedData,
          Plot.pointerX({
            x: 'voteIndex',
            y: 'accumulatedComoAmount',
            stroke: 'red',
          }),
        ),
        Plot.tip(
          transformedData,
          Plot.pointerX({
            x: 'voteIndex',
            y: 'accumulatedComoAmount',
            lineWidth: Infinity,
            fontVariant: 'tabular-nums',
            title: d =>
              [`Voter ${d.voter}`, `${d.comoAmount} COMO`].join('\n\n'),
          }),
        ),
      ],
    });
    plotContainerRef.current.appendChild(plot);
    function listener() {
      let focusedData = plot.value;
      if (focusedData && scrollContainerRef.current) {
        let rowToScroll = scrollContainerRef.current.querySelector(
          `#vote-${focusedData.voteIndex}`,
        );
        if (rowToScroll) {
          rowToScroll.scrollIntoView();
        }
      }
    }
    plot.addEventListener('input', listener);
    return () => {
      plot.removeEventListener('input', listener);
      plot.remove();
    };
  }, [nicknames, voteLogs, plotSize]);

  return (
    <div className='h-[calc(100dvh_-_4rem)]'>
      {voteLogs.length ? (
        <div className='h-full w-full' ref={plotContainerRef} />
      ) : (
        <Spinner className='h-full w-full' />
      )}
      <AnimatePresence>
        {voteLogs.length > 0 && (
          <motion.div
            className='fixed inset-x-6 bottom-4 max-w-xl mx-auto'
            variants={TRANSITION_VARIANTS.scaleSpringOpacity}
            initial='initial'
            animate='enter'
            exit='exit'
          >
            <Card className='relative'>
              <CardHeader>
                <div className='flex-1 font-bold'>Realtime Votes</div>
                <div>
                  <Switch
                    size='sm'
                    isSelected={shouldAutoScroll}
                    onValueChange={setShouldAutoScroll}
                  >
                    Auto Scroll
                  </Switch>
                  <button
                    className='appearance-none select-none p-2 text-foreground-500 rounded-full hover:bg-default-100 active:bg-default-200 tap-highlight-transparent data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2'
                    onClick={() => setIsCardOpen(!isCardOpen)}
                  >
                    {isCardOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
                  </button>
                </div>
              </CardHeader>
              {isCardOpen ? (
                <>
                  <Divider />
                  <CardBody>
                    <ScrollShadow
                      className='max-h-[40vh]'
                      ref={scrollContainerRef}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Voter</th>
                            <th>Como</th>
                          </tr>
                        </thead>
                        <tbody>
                          {voteLogs.map((voteLog, index) => (
                            <tr key={index} id={`vote-${voteLog.voteIndex}`}>
                              <td>{`${voteLog.voteIndex}`}</td>
                              <td>
                                <Link
                                  to={
                                    nicknames[voteLog.voter]
                                      ? `/@${nicknames[voteLog.voter]}`
                                      : `/${voteLog.voter}`
                                  }
                                >
                                  {nicknames[voteLog.voter] || voteLog.voter}
                                </Link>
                              </td>
                              <td>{`${
                                voteLog.comoAmount / 1000000000000000000n
                              }`}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </ScrollShadow>
                  </CardBody>
                </>
              ) : null}
            </Card>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
async function fetchNicknames(
  addresses: string[],
): Promise<{ [address: string]: string }> {
  let addressGroups = [];
  for (let i = 0; i < addresses.length; i += 150) {
    addressGroups.push(addresses.slice(i, i + 150));
  }

  async function fetchGroupNicknames(group: string[]) {
    let response = await fetch(
      `https://cache.nova.gd/user/v1/by-address/${group.join(',')}`,
    );
    let users = (await response.json()) as {
      nickname: string;
      address: string;
    }[];
    return Object.fromEntries(
      users.map(({ nickname, address }) => [address, nickname]),
    );
  }

  try {
    return (await Promise.all(addressGroups.map(fetchGroupNicknames))).reduce(
      (acc, groupNicknames) => ({ ...acc, ...groupNicknames }),
      {},
    );
  } catch (error) {
    console.error('Failed to fetch nicknames:', error);
    return {};
  }
}
