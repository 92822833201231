import { useState, useCallback, useLayoutEffect, Dispatch } from 'react';

import { useSearchParams, NavigateOptions } from 'react-router-dom';

export function useSearchParamState(
  name: string,
  defaultValue: string = '',
  navigateOpts?: NavigateOptions,
): [string, Dispatch<string>] {
  let [searchParams, setSearchParams] = useSearchParams();
  let currentValue = searchParams.get(name) ?? defaultValue;
  let [value, setValue_] = useState(currentValue);
  // https://react.dev/reference/react/useState#storing-information-from-previous-renders
  if (!navigateOpts?.replace && value !== currentValue) {
    setValue_(currentValue);
  }
  let setValue = useCallback(
    (value: string) => {
      let newSearchParams = Object.fromEntries(
        Array.from(searchParams.entries()).filter(([key]) => key !== name),
      );
      setValue_(value);
      if (value === defaultValue) {
        setSearchParams(newSearchParams, navigateOpts);
      } else {
        setSearchParams({ ...newSearchParams, [name]: value }, navigateOpts);
      }
    },
    [searchParams],
  );
  return [value, setValue];
}

export function useSearchParamStates(
  name: string,
  navigateOpts?: NavigateOptions,
): [string[], Dispatch<string[]>] {
  let [searchParams, setSearchParams] = useSearchParams();
  let currentValues = searchParams.getAll(name);
  let [values, setValues_] = useState(currentValues);
  // https://react.dev/reference/react/useState#storing-information-from-previous-renders
  if (
    !navigateOpts?.replace &&
    (values.length !== currentValues.length ||
      values.some((value, i) => value !== currentValues[i]))
  ) {
    setValues_(currentValues);
  }
  let setValues = useCallback(
    (values: string[]) => {
      let newSearchParams = Object.fromEntries(
        Array.from(searchParams.entries()).filter(([key]) => key !== name),
      );
      setValues_(values);
      if (values.length === 0) {
        setSearchParams(newSearchParams, navigateOpts);
      } else {
        setSearchParams({ ...newSearchParams, [name]: values }, navigateOpts);
      }
    },
    [searchParams],
  );
  return [values, setValues];
}

export function useMediaQuery(
  query: string,
  onChange?: (matches: boolean) => void,
) {
  let [matches, setMatches] = useState(false);
  useLayoutEffect(() => {
    let mediaQuery = window.matchMedia(query);
    setMatches(mediaQuery.matches);
    function listener(e: MediaQueryListEvent) {
      setMatches(e.matches);
    }
    mediaQuery.addEventListener('change', listener);
    return () => mediaQuery.removeEventListener('change', listener);
  }, [query, onChange]);
  return matches;
}
