import {
  ModalHeader,
  ModalBody,
  Tabs,
  Tab,
  Checkbox,
  Input,
  Button,
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  ModalFooter,
} from '@nextui-org/react';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Objekt } from '../cosmo';
import { HashIcon, SendIcon } from '../icons';
import { polarisClient } from '../polaris';
import { getNicknames, getTimestamps } from '../routes/objekts';

export function ObjektModal({
  objekt,
  onClose,
  ownedObjekts,
  selectedObjekts,
  setSelectedObjekts,
}: {
  objekt: Objekt;
  onClose: () => void;
  ownedObjekts: Objekt[];
  selectedObjekts: Objekt[];
  setSelectedObjekts: Dispatch<SetStateAction<Objekt[]>>;
}) {
  let [objektNo, setObjektNo] = useState('');
  let [objektCount, setObjektCount] = useState<number>(0);

  let [transfers, setTransfers] = useState<
    | {
        from: string;
        to: string;
        blockNumber: number;
      }[]
    | null
  >(null);
  let [nicknames, setNicknames] = useState<{
    [address: string]: string;
  }>({});
  let [timestamps, setTimestamps] = useState<{
    [blockNumber: number]: number;
  }>({});

  function onToggleObjektSelection(objekt: Objekt, isSelected: boolean) {
    setSelectedObjekts(currentSelectedObjekts => {
      if (isSelected) {
        return [...currentSelectedObjekts, objekt];
      } else {
        return currentSelectedObjekts.filter(
          selectedObjekt => selectedObjekt !== objekt,
        );
      }
    });
  }

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      let objektCount = await polarisClient.getObjektCount(objekt.collectionId);
      if (!isCancelled) {
        setObjektCount(objektCount);
      }
    })();
    return () => void (isCancelled = true);
  }, [objekt]);

  let [isLoading, setIsLoading] = useState(false);
  let [isFlipped, setIsFlipped] = useState(false);

  return (
    <>
      <ModalHeader>{objekt.collectionId}</ModalHeader>
      <ModalBody>
        <div className='flex flex-col sm:flex-row items-stretch gap-6'>
          <div className='w-[12rem] self-center sm:min-w-[16rem]'>
            <div
              className='relative w-full aspect-w-[1083] aspect-h-[1673] transition-transform'
              style={{
                transformStyle: 'preserve-3d',
                transform: isFlipped ? 'rotateY(180deg)' : '',
              }}
              onClick={() => setIsFlipped(!isFlipped)}
            >
              <div
                className='absolute inset-0'
                style={{ backfaceVisibility: 'hidden' }}
              >
                <img src={objekt.frontImage} alt='Objekt Image' />
              </div>
              <div
                className='absolute inset-0'
                style={{
                  backfaceVisibility: 'hidden',
                  transform: 'rotateY(180deg)',
                }}
              >
                <img src={objekt.backImage} alt='Objekt Back Image' />
              </div>
            </div>
          </div>
          <div className='flex-1'>
            <div>
              <span className='font-bold'>Artists:</span>{' '}
              {objekt.artists?.join(', ')}
            </div>
            <div>
              <span className='font-bold'>Season:</span> {objekt.season}
            </div>
            <div>
              <span className='font-bold'>Class:</span> {objekt.class}
            </div>
            <div>
              <span className='font-bold'>Member:</span> {objekt.member}
            </div>
            <div>
              <span className='font-bold'>Total Objekt Count:</span>{' '}
              {objektCount}
            </div>
            <Tabs aria-label='Tabs' className='mt-4'>
              <Tab key='owned' title='Owned'>
                {/* TODO: handle scrolling */}
                {ownedObjekts.length ? (
                  <div className='flex flex-col'>
                    {ownedObjekts.map(ownedObjekt => (
                      <Checkbox
                        key={ownedObjekt.tokenId}
                        isSelected={selectedObjekts.includes(ownedObjekt)}
                        isDisabled={!ownedObjekt.transferable}
                        onValueChange={isSelected =>
                          onToggleObjektSelection(ownedObjekt, isSelected)
                        }
                      >
                        {ownedObjekt.collectionId} #{ownedObjekt.objektNo}
                      </Checkbox>
                    ))}
                  </div>
                ) : (
                  <div className='flex h-40 justify-center items-center text-foreground-400'>
                    No objekts owned.
                  </div>
                )}
              </Tab>
              <Tab key='details' title='Details'>
                <form
                  className='flex flex-row gap-3'
                  onSubmit={async e => {
                    e.preventDefault();
                    if (!objektNo) {
                      return;
                    }
                    setIsLoading(true);
                    try {
                      let transfers = await polarisClient.getObjektTransfers(
                        objekt.collectionId,
                        parseInt(objektNo),
                      );
                      let addresses = new Set<string>();
                      let blockNumbers = new Set<number>();
                      for (let transfer of transfers) {
                        if (
                          transfer.from !==
                          '0x0000000000000000000000000000000000000000'
                        ) {
                          addresses.add(transfer.from);
                        }
                        addresses.add(transfer.to);
                        blockNumbers.add(transfer.blockNumber);
                      }
                      let [nicknames, timestamps] = await Promise.all([
                        getNicknames(Array.from(addresses)),
                        getTimestamps(Array.from(blockNumbers)),
                      ]);
                      setTransfers(transfers);
                      setNicknames(nicknames);
                      setTimestamps(timestamps);
                    } finally {
                      setIsLoading(false);
                    }
                  }}
                >
                  <Input
                    type='number'
                    inputMode='numeric'
                    pattern='[0-9]*'
                    placeholder='Objekt Number'
                    startContent={
                      <HashIcon className='text-foreground-500 w-6 h-6' />
                    }
                    value={objektNo}
                    onValueChange={setObjektNo}
                    classNames={{ input: 'text-base placeholder:text-small' }}
                    disabled={isLoading}
                  />
                  <Button
                    type='submit'
                    isIconOnly
                    color='primary'
                    variant='flat'
                    isLoading={isLoading}
                  >
                    <SendIcon className='w-6 h-6' />
                  </Button>
                </form>
                {/* TODO: handle scrolling */}
                <Table
                  className='mt-4'
                  removeWrapper
                  isStriped
                  layout='fixed'
                  classNames={{ td: 'group-data-[odd=true]:before:-z-10' }}
                >
                  <TableHeader>
                    <TableColumn className='w-40'>Owner</TableColumn>
                    <TableColumn>Date</TableColumn>
                  </TableHeader>
                  <TableBody
                    emptyContent={
                      transfers === null
                        ? 'No objekt specified.'
                        : 'Not minted yet.'
                    }
                  >
                    {(transfers ?? []).map((transfer, index) => (
                      <TableRow key={index}>
                        <TableCell className='overflow-hidden text-ellipsis'>
                          {nicknames[transfer.to] ? (
                            <Link
                              className='inline'
                              to={`/@${nicknames[transfer.to]}`}
                            >
                              {nicknames[transfer.to]}
                            </Link>
                          ) : (
                            <Link className='inline' to={`/${transfer.to}`}>
                              {transfer.to}
                            </Link>
                          )}
                        </TableCell>
                        <TableCell>
                          {new Date(
                            timestamps[transfer.blockNumber] * 1000,
                          ).toLocaleString('en-US')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Tab>
            </Tabs>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='danger' onPress={onClose}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
}
