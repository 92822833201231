import 'inter-ui/inter.css';

import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { NextUIProvider } from '@nextui-org/react';
import {
  init as initSentry,
  browserTracingIntegration,
  replayIntegration,
} from '@sentry/react';

initSentry({
  dsn: 'https://55ac6857dd124992edb028afcf22ead2@o4505666811920384.ingest.sentry.io/4506268814802944',
  integrations: [
    browserTracingIntegration({
      tracePropagationTargets: ['localhost'],
    }),
    replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

import { CredentialsProvider } from './credentials';
import Root from './routes/root';
import Gravity from './routes/gravity';
import GravityDetail from './routes/gravity-detail';
import Objekts, {
  loader as objektsLoader,
  ObjektsTabRoute,
  TradesTabRoute,
} from './routes/objekts';
import OtherObjekts, {
  OtherObjektsTabRoute,
  OtherTradesTabRoute,
  loader as otherObjektsLoader,
  otherObjektsTabLoader,
} from './routes/other-objekts';

let router = createBrowserRouter([
  {
    path: '',
    element: <Root />,
    children: [
      // FIXME: I know React Router isn't expected to be used like this.
      {
        path: '',
        element: <Objekts />,
        children: [
          {
            path: '',
            element: <ObjektsTabRoute />,
            loader: objektsLoader,
            // To not reinitiate fetching objekts
            shouldRevalidate: ({ currentUrl, nextUrl }) => {
              if (currentUrl.pathname === nextUrl.pathname) {
                return false;
              }
              return true;
            },
          },
          {
            path: 'wants',
            element: <ObjektsTabRoute />,
            loader: objektsLoader,
            // To not reinitiate fetching objekts
            shouldRevalidate: ({ currentUrl, nextUrl }) => {
              if (currentUrl.pathname === nextUrl.pathname) {
                return false;
              }
              return true;
            },
          },
          {
            path: 'trades',
            element: <TradesTabRoute />,
          },
        ],
      },
      { path: 'gravity', element: <Gravity /> },
      { path: 'gravity/:gravityId', element: <GravityDetail /> },
      {
        path: ':userId',
        element: <OtherObjekts />,
        loader: otherObjektsLoader,
        children: [
          {
            path: '',
            element: <OtherObjektsTabRoute />,
            loader: otherObjektsTabLoader,
            shouldRevalidate: ({ currentUrl, nextUrl }) => {
              if (currentUrl.pathname === nextUrl.pathname) {
                return false;
              }
              return true;
            },
          },
          {
            path: 'wants',
            element: <OtherObjektsTabRoute />,
            loader: otherObjektsTabLoader,
            shouldRevalidate: ({ currentUrl, nextUrl }) => {
              if (currentUrl.pathname === nextUrl.pathname) {
                return false;
              }
              return true;
            },
          },
          {
            path: 'haves',
            element: <OtherObjektsTabRoute />,
            loader: otherObjektsTabLoader,
            shouldRevalidate: ({ currentUrl, nextUrl }) => {
              if (currentUrl.pathname === nextUrl.pathname) {
                return false;
              }
              return true;
            },
          },
          {
            path: 'trades',
            element: <OtherTradesTabRoute />,
          },
        ],
      },
    ],
  },
]);

createRoot(document.getElementById('root')!).render(
  <NextUIProvider>
    <CredentialsProvider>
      <RouterProvider router={router} />
    </CredentialsProvider>
  </NextUIProvider>,
);
