import { Fragment, useMemo, useState } from 'react';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useParams,
  useMatch,
  useNavigate,
  useOutletContext,
  Outlet,
  NavLink,
} from 'react-router-dom';
import {
  User,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Chip,
  Divider,
  ScrollShadow,
  Select,
  SelectItem,
  Radio,
  RadioGroup,
  Tabs,
  Tab,
  Modal,
  ModalContent,
  useDisclosure,
} from '@nextui-org/react';

import { ChevronDownIcon, ChevronUpIcon } from '@nextui-org/shared-icons';
import { motion, AnimatePresence } from 'framer-motion';
import { TRANSITION_VARIANTS } from '@nextui-org/framer-utils';

import { Search } from 'js-search';

import {
  Artist,
  CosmoClient,
  Objekt,
  SearchedProfile,
  cosmoClient,
} from '../cosmo';
import { polarisClient } from '../polaris';

import { ObjektModal } from '../components/objekt-modal';
import { ObjektFilters, ObjektList } from '../components/objekt-list';
import { FilterIcon } from '../icons';
import {
  TradesTab,
  useMyOwnedObjekts,
  useMyWantsList,
  useOwnedObjekts,
  useWantsList,
} from './objekts';
import {
  useMediaQuery,
  useSearchParamState,
  useSearchParamStates,
} from '../hooks';
import { filterObjekts } from '../utils';

export async function loader({ params }: LoaderFunctionArgs) {
  let { userId } = params as { userId: string };
  let address: string;
  let profileImageUrl = '';
  if (userId.startsWith('@')) {
    let nickname = userId.substring(1);
    let profile: SearchedProfile;
    if (!cosmoClient.credentials) {
      let proxyClient = new CosmoClient('https://proxy.goranmoomin.dev/cosmo/');
      profile = (await proxyClient.searchUsers(nickname)).results[0];
    } else {
      profile = (await cosmoClient.searchUsers(nickname)).results[0];
    }
    address = profile.address;
    profileImageUrl = profile.profileImageUrl;
    if (profile.profile.length) {
      profileImageUrl = profile.profile[0].image.original;
    }
  } else if (userId.startsWith('0x')) {
    address = userId;
  } else {
    throw new Error('unexpected userId given');
  }
  let [tripleS, artms] = await Promise.all([
    cosmoClient.getArtist('tripleS'),
    cosmoClient.getArtist('artms'),
  ]);
  return {
    address,
    profileImageUrl,
    artists: [tripleS, artms],
  };
}

function ObjektsTab({
  objekts,
  artists,
  address,
  userId,
}: {
  objekts: Objekt[];
  artists: Artist[];
  address: string;
  userId: string;
}) {
  let ownedObjekts = useOwnedObjekts(address);
  let myOwnedObjekts = useMyOwnedObjekts();
  let wantsList = useWantsList(address);
  let [myWantsList, refreshMyWantsList] = useMyWantsList();

  let search = useMemo(() => {
    let search = new Search('collectionId');
    search.addIndex('artists');
    search.addIndex('season');
    search.addIndex('class');
    search.addIndex('member');
    search.addIndex('collectionId');
    search.addDocuments(objekts);
    return search;
  }, [objekts]);

  let artistOptions = useMemo(
    () =>
      Array.from(
        new Set(objekts.flatMap(objekt => objekt.artists ?? ['tripleS'])),
      ),
    [objekts],
  );
  let [artistName, setArtistName] = useSearchParamState('artist', 'tripleS');
  let artist = artists.find(artist => artist.name === artistName);

  objekts = useMemo(
    () =>
      objekts
        .filter(objekt => (objekt.artists ?? ['tripleS']).includes(artistName))
        .sort((a, b) => {
          let aIndex =
            artist?.members.findIndex(member => member.name === a.member) ?? -1;
          let bIndex =
            artist?.members.findIndex(member => member.name === b.member) ?? -1;
          if (aIndex === -1) {
            return 1;
          } else if (bIndex === -1) {
            return -1;
          } else {
            return aIndex - bIndex;
          }
        }),
    [objekts, artistName, artist],
  );
  let memberOptions = useMemo(
    () =>
      Array.from(new Set(objekts.map(objekt => objekt.member))).sort((a, b) => {
        let aIndex =
          artist?.members.findIndex(member => member.name === a) ?? -1;
        let bIndex =
          artist?.members.findIndex(member => member.name === b) ?? -1;
        if (aIndex === -1) {
          return 1;
        } else if (bIndex === -1) {
          return -1;
        } else {
          return aIndex - bIndex;
        }
      }),
    [objekts],
  );
  let seasonOptions = useMemo(
    () => Array.from(new Set(objekts.map(objekt => objekt.season))),
    [objekts],
  );
  let classOptions = useMemo(
    () => Array.from(new Set(objekts.map(objekt => objekt.class))),
    [objekts],
  );

  let [members, setMembers] = useSearchParamStates('members');
  let [seasons, setSeasons] = useSearchParamStates('seasons');
  let [classes, setClasses] = useSearchParamStates('classes');
  let [query, setQuery] = useSearchParamState('q', '', { replace: true });
  let [filterOption, setFilterOption] = useState<
    'all' | 'owned' | 'transferable'
  >('owned');

  let isWantsList = !!useMatch(':userId/wants');
  let isHavesList = !!useMatch(':userId/haves');
  let selectedList: 'all' | 'wants' | 'haves' = isWantsList
    ? 'wants'
    : isHavesList
      ? 'haves'
      : 'all';
  let navigate = useNavigate();
  function setSelectedList(selectedList: 'all' | 'wants' | 'haves') {
    switch (selectedList) {
      case 'all':
        navigate(`/${userId}`);
        break;
      case 'wants':
        navigate(`/${userId}/wants`);
        break;
      case 'haves':
        navigate(`/${userId}/haves`);
        break;
    }
  }
  if (selectedList === 'wants') {
    ownedObjekts = myOwnedObjekts;
  }

  objekts = useMemo(() => {
    objekts = filterObjekts(
      objekts,
      members,
      seasons,
      classes,
      filterOption,
      ownedObjekts,
    );
    if (selectedList === 'wants') {
      // the other user's wants, objekts displayed should be what I have
      objekts = objekts.filter(objekt =>
        wantsList.includes(objekt.collectionId),
      );
    } else if (selectedList === 'haves') {
      // My wants, objekts displayed should be what the other user has
      objekts = objekts.filter(objekt =>
        myWantsList.includes(objekt.collectionId),
      );
    }
    return objekts;
  }, [
    objekts,
    members,
    seasons,
    classes,
    filterOption,
    ownedObjekts,
    selectedList,
    wantsList,
    myWantsList,
  ]);

  // query changes frequently, so should be memoized separately.
  objekts = useMemo(() => {
    if (query) {
      objekts = (search.search(query) as Objekt[]).filter(objekt =>
        objekts.includes(objekt),
      );
    }
    return objekts;
  }, [objekts, search, query]);

  let [selectedObjekts, setSelectedObjekts] = useState<Objekt[]>([]);

  let [isCardOpen, setIsCardOpen] = useState(true);
  let [selectedObjektForModal, setSelectedObjektForModal] =
    useState<Objekt | null>(null);
  let {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onOpenChange: onModalOpenChange,
  } = useDisclosure();

  function onObjektPress(objekt: Objekt) {
    setSelectedObjektForModal(objekt);
    onModalOpen();
  }

  let isMobile = !useMediaQuery('(min-width: 640px)');

  let [isFiltersOpen, setIsFiltersOpen] = useState(false);
  let isFiltersActive =
    members.length || seasons.length || classes.length || query;

  return (
    <div className='flex flex-col gap-4'>
      <div>
        {isMobile ? (
          <Chip
            as='button'
            color='primary'
            variant={isFiltersActive || isFiltersOpen ? 'solid' : 'flat'}
            startContent={<FilterIcon className='w-4 h-4 ml-1' />}
            onClick={() => setIsFiltersOpen(!isFiltersOpen)}
          >
            Filters
          </Chip>
        ) : null}
        <AnimatePresence>
          {!isMobile || (isFiltersOpen && isMobile) ? (
            <motion.div
              variants={TRANSITION_VARIANTS.collapse}
              animate='enter'
              exit='exit'
              initial='exit'
            >
              <div className={isMobile ? 'pt-4' : ''}>
                <ObjektFilters
                  artist={artistName}
                  members={members}
                  seasons={seasons}
                  classes={classes}
                  artistOptions={artistOptions}
                  memberOptions={memberOptions}
                  seasonOptions={seasonOptions}
                  classOptions={classOptions}
                  setArtist={setArtistName}
                  setMembers={setMembers}
                  setSeasons={setSeasons}
                  setClasses={setClasses}
                  query={query}
                  setQuery={setQuery}
                />
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
      <div className='flex flex-col w-full sm:flex-row gap-4'>
        <RadioGroup
          label='Display options'
          orientation='horizontal'
          value={filterOption}
          onValueChange={value =>
            setFilterOption(value as 'all' | 'owned' | 'transferable')
          }
          className='grow order-2 sm:order-1'
        >
          <Radio value='all'>All</Radio>
          <Radio value='owned'>
            Owned
            {selectedList === 'wants'
              ? ' by me'
              : selectedList === 'haves'
                ? ` by them`
                : ''}
          </Radio>
          <Radio value='transferable'>
            Sendable
            {selectedList === 'wants'
              ? ' by me'
              : selectedList === 'haves'
                ? ` by them`
                : ''}
          </Radio>
        </RadioGroup>
        <Select
          label='Display List'
          selectedKeys={[selectedList]}
          onSelectionChange={selectedKeys =>
            setSelectedList(
              Array.from(selectedKeys as Set<'all' | 'wants' | 'haves'>)[0],
            )
          }
          className='w-[auto] sm:max-w-xs order-1 sm:order-2 flex-1'
        >
          <SelectItem key='all' value='all'>
            All Objekts
          </SelectItem>
          <SelectItem key='wants' value='wants'>
            Their Wants
          </SelectItem>
          <SelectItem key='haves' value='haves'>
            My Wants
          </SelectItem>
        </Select>
      </div>
      <div className='text-medium font-medium'>
        {query && `Search results for: ${query}`}
      </div>
      <Modal size='3xl' isOpen={isModalOpen} onOpenChange={onModalOpenChange}>
        <ModalContent>
          {onClose => (
            <ObjektModal
              objekt={selectedObjektForModal!}
              onClose={onClose}
              ownedObjekts={ownedObjekts
                .filter(
                  objekt =>
                    objekt.collectionId ===
                    selectedObjektForModal!.collectionId,
                )
                // toSorted is from ES2023
                // .toSorted((a, b) => a.objektNo - b.objektNo)
                // since .filter returns a new array, an in-place sort works.
                .sort((a, b) => a.objektNo - b.objektNo)}
              selectedObjekts={selectedObjekts}
              setSelectedObjekts={setSelectedObjekts}
            />
          )}
        </ModalContent>
      </Modal>
      {objekts.length === 0 ? (
        <div className='flex flex-col gap-2 items-center'>
          <div className='text-small font-medium text-foreground-500'>
            {selectedList === 'haves'
              ? 'Mark any objekt as Want to share your Want list.'
              : selectedList === 'wants'
                ? 'No objekts matches.'
                : 'No objekt matches your query.'}
          </div>
          {selectedList === 'haves' && (
            <img
              className='max-w-96'
              src={
                new URL('../resources/add-to-list.png', import.meta.url).href
              }
            />
          )}
        </div>
      ) : null}
      <ObjektList
        objekts={objekts}
        ownedObjekts={ownedObjekts}
        selectedObjekts={selectedObjekts}
        setSelectedObjekts={setSelectedObjekts}
        onObjektPress={onObjektPress}
        wantsList={myWantsList}
        onListChange={refreshMyWantsList}
      />
      <AnimatePresence>
        {selectedObjekts.length > 0 && (
          <motion.div
            className='fixed inset-x-6 bottom-4 max-w-lg mx-auto'
            variants={TRANSITION_VARIANTS.scaleSpringOpacity}
            initial='initial'
            animate='enter'
            exit='exit'
          >
            <Card className='max-h-[60vh]'>
              <CardHeader>
                <div className='flex-1 font-bold'>
                  {selectedObjekts.length} Selected Objekt
                  {selectedObjekts.length > 1 ? 's' : ''}
                </div>
                <button
                  className='appearance-none select-none p-2 text-foreground-500 rounded-full hover:bg-default-100 active:bg-default-200 tap-highlight-transparent data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2'
                  onClick={() => setIsCardOpen(!isCardOpen)}
                >
                  {isCardOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
                </button>
              </CardHeader>
              {isCardOpen ? (
                <>
                  <Divider />
                  <ScrollShadow>
                    <CardBody>
                      {selectedObjekts.map(objekt => (
                        <Fragment key={objekt.tokenId}>
                          <div className='flex h-8'>
                            <Checkbox
                              className='max-w-none flex-1'
                              isSelected={selectedObjekts.includes(objekt)}
                              onValueChange={isSelected => {
                                if (isSelected) {
                                  setSelectedObjekts([
                                    ...selectedObjekts,
                                    objekt,
                                  ]);
                                } else {
                                  setSelectedObjekts(
                                    selectedObjekts.filter(
                                      selectedObjekt =>
                                        selectedObjekt !== objekt,
                                    ),
                                  );
                                }
                              }}
                            >
                              {objekt.collectionId} #{objekt.objektNo}
                            </Checkbox>
                          </div>
                        </Fragment>
                      ))}
                    </CardBody>
                  </ScrollShadow>
                </>
              ) : null}
            </Card>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export async function otherObjektsTabLoader() {
  let [tripleS, artms, objekts] = await Promise.all([
    cosmoClient.getArtist('tripleS'),
    cosmoClient.getArtist('artms'),
    polarisClient.getAllObjekts(),
  ]);
  return {
    objekts,
    artists: [tripleS, artms],
  };
}

export function OtherObjektsTabRoute() {
  let { userId } = useParams() as { userId: string };
  let { address } = useOutletContext() as { address: string };
  let { objekts, artists } = useLoaderData() as {
    objekts: Objekt[];
    artists: Artist[];
  };

  return (
    <ObjektsTab
      objekts={objekts}
      artists={artists}
      address={address}
      userId={userId}
    />
  );
}

export function OtherTradesTabRoute() {
  let { address } = useOutletContext() as { address: string };
  return <TradesTab address={address} />;
}

export default function OtherObjekts() {
  let { userId } = useParams() as { userId: string };
  let { address, profileImageUrl } = useLoaderData() as {
    address: string;
    profileImageUrl: string;
  };
  let isTradeTab = !!useMatch(':userId/trades');

  return (
    <div className='max-w-5xl mx-auto px-6 py-2 flex flex-col gap-4'>
      <User
        name={userId}
        description={userId.startsWith('@') ? address : undefined}
        avatarProps={{
          src:
            profileImageUrl ||
            'https://static.cosmo.fans/uploads/images/img_profile_gallag@3x.png',
          className: 'h-20 w-20 shrink-0',
        }}
        classNames={{
          base: 'justify-start my-4',
          name: 'text-large',
          wrapper: 'min-w-0',
          description: 'text-base w-full overflow-hidden text-ellipsis',
        }}
      />
      <Tabs
        classNames={{ panel: 'p-0' }}
        selectedKey={isTradeTab ? 'trades' : 'objekts'}
      >
        <Tab key='objekts' title={<NavLink to=''>Objekts</NavLink>}>
          <Outlet context={{ address }} />
        </Tab>
        <Tab key='trades' title={<NavLink to='trades'>Trades</NavLink>}>
          <Outlet context={{ address }} />
        </Tab>
      </Tabs>
    </div>
  );
}
