import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AlchemyProvider,
  Contract,
  AbiCoder,
  Interface,
  Wallet,
  parseEther,
} from 'ethers';
import { cosmoClient, Gravity, PollDetail } from '../cosmo';
import { getWalletMnemonic } from '../ramper';
import { useCurrentAccount } from '../credentials';

let provider = new AlchemyProvider('matic', 'jKHL8FBDC9OR14KUb_n-J0_5KoF9hjDo');
let contractAddress = '0xc3E5ad11aE2F00c740E74B81f134426A3331D950';
let contractAbi = [
  'event Finalized(uint256 indexed pollId, uint256 burned)',
  'event Initialized(uint8 version)',
  'event PollCreated(uint256 pollId)',
  'event Revealed(uint256 indexed pollId, uint256 revealedVotes, uint256 remainingVotes)',
  'event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)',
  'event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)',
  'event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)',
  'event Voted(uint256 indexed pollId, uint256 voteIndex, address voter, uint256 comoAmount, bytes32 hash)',
  'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
  'function OPERATOR_ROLE() view returns (bytes32)',
  'function candidates(uint256 pollId) view returns (string[])',
  'function comoContract() view returns (address)',
  'function createPoll(string title_, string[] candidates_, uint256 startAt_, uint256 due_, uint256 minimumCOMO_)',
  'function finalize(uint256 pollId)',
  'function getRoleAdmin(bytes32 role) view returns (bytes32)',
  'function grantRole(bytes32 role, address account)',
  'function hasRole(bytes32 role, address account) view returns (bool)',
  'function hashes(uint256, bytes32) view returns (bool)',
  'function initialize(address voteSignerAddress_, address comoAddress_)',
  'function isInProgress(uint256 pollId) view returns (bool)',
  'function isRevealedVote(uint256, uint256) view returns (bool)',
  'function pollResult(uint256 pollId) view returns (tuple(string candidate, uint256 votes)[])',
  'function polls(uint256) view returns (string title, uint256 startAt, uint256 due, uint256 minimumCOMO, uint256 totalVotedCOMO, uint256 revealedVotes, bool finalized)',
  'function remainingVotes(uint256 pollId) view returns (uint256)',
  'function renounceRole(bytes32 role, address account)',
  'function reset(uint256 pollId, uint256 missingOffset, tuple(uint256 comoAmount, bytes32 hash)[] missingCommitData)',
  'function reveal(uint256 pollId, tuple(uint256 votedCandidateId, bytes32 salt)[] data, uint256 offset)',
  'function revokeRole(bytes32 role, address account)',
  'function setVoteSignerAddress(address addr)',
  'function supportsInterface(bytes4 interfaceId) view returns (bool)',
  'function tokensReceived(address operator, address from, address to, uint256 amount, bytes userData, bytes operatorData)',
  'function totalVotes(uint256 pollId) view returns (uint256)',
  'function userVoteResult(uint256 pollId, address voter) view returns (tuple(string candidate, uint256 votes)[])',
  'function userVoteResults(uint256, address, uint256) view returns (uint256)',
  'function voteSignerAddress() view returns (address)',
  'function voters(uint256, uint256) view returns (address)',
  'function votes(uint256 pollId) view returns (tuple(uint256 comoAmount, bytes32 hash)[])',
  'function votesPerCandidates(uint256 pollId) view returns (uint256[])',
];
let contract = new Contract(contractAddress, contractAbi, provider);

function GravityDetail() {
  let { gravityId } = useParams() as { gravityId: string };
  let [gravity, setGravity] = useState<Gravity | null>(null);
  let [pollDetails, setPollDetails] = useState<{
    [pollId: number]: PollDetail;
  }>({});
  let [voteResults, setVoteResults] = useState<{
    [pollId: number]: bigint[];
  }>({});
  let [choiceId, setChoiceId] = useState<string | null>(null);
  let [comoAmount, setComoAmount] = useState<number>(1);
  let [isVoting, setIsVoting] = useState(false);
  let [voteError, setVoteError] = useState<string | null>(null);

  let currentAccount = useCurrentAccount();
  let ramperWalletSecrets = currentAccount?.ramperWalletSecrets;

  useEffect(() => {
    if (!currentAccount) return;
    let isCancelled = false;
    (async () => {
      let gravityData = await cosmoClient.getGravity(parseInt(gravityId!));
      if (!isCancelled) {
        setGravity(gravityData);
      }

      let pollDetailsData = await Promise.all(
        gravityData.polls.map(poll =>
          cosmoClient.getPollDetail(gravityData.id, poll.id),
        ),
      );
      if (!isCancelled) {
        setPollDetails(
          Object.fromEntries(
            pollDetailsData.map(detail => [detail.id, detail]),
          ),
        );
      }
    })();
    return () => void (isCancelled = true);
  }, [gravityId, currentAccount]);

  useEffect(() => {
    if (!gravity) return;
    let isCancelled = false;
    (async () => {
      let voteResultsData = await Promise.all(
        gravity.polls
          .filter(poll => poll.finalized)
          .map(poll =>
            contract
              .votesPerCandidates(poll.pollIdOnChain || poll.id)
              .then(votes => ({ pollId: poll.id, votes })),
          ),
      );
      if (!isCancelled) {
        setVoteResults(
          Object.fromEntries(
            voteResultsData.map(({ pollId, votes }) => [pollId, votes]),
          ),
        );
      }
    })();
    return () => void (isCancelled = true);
  }, [gravity]);

  let handleVote = async (pollId: number) => {
    if (!choiceId || comoAmount <= 0 || !ramperWalletSecrets) {
      setVoteError(
        "Please select a choice, enter a valid COMO amount, and ensure you're logged in",
      );
      return;
    }

    setIsVoting(true);
    setVoteError(null);
    try {
      let { version, dek, encryptedKey } = ramperWalletSecrets;
      let wallet = Wallet.fromPhrase(
        await getWalletMnemonic(version, dek, encryptedKey),
        provider,
      );

      let { callData } = await cosmoClient.fabricateVote(
        'tripleS',
        pollId,
        choiceId,
        comoAmount,
      );

      let abiCoder = new AbiCoder();
      let encodedVoteData = abiCoder.encode(
        ['uint256', 'bytes32', 'bytes'],
        [callData.pollIdOnChain, callData.hash, callData.signature],
      );

      let erc777Interface = new Interface([
        'function send(address to, uint256 amount, bytes calldata data) external',
      ]);

      let txData = erc777Interface.encodeFunctionData('send', [
        contractAddress,
        parseEther(comoAmount.toString()),
        encodedVoteData,
      ]);

      console.log(txData);

      let tx = {
        to: contractAddress,
        data: txData,
      };

      // FIXME: This keeps failing with the error 'execution reverted'
      let txResponse = await wallet.sendTransaction(tx);
      await txResponse.wait();
    } catch (error) {
      console.error(error);
      setVoteError('An error occurred while voting. Please try again.');
    } finally {
      setIsVoting(false);
    }
  };

  return (
    <div>
      {gravity ? (
        <div>
          <h1>{gravity.title}</h1>
          <p>{gravity.description}</p>
          <div>
            <h2>Polls</h2>
            <ul>
              {gravity.polls.map(poll => (
                <li key={poll.id}>
                  <h3>{poll.title}</h3>
                  <p>Start Date: {new Date(poll.startDate).toLocaleString()}</p>
                  <p>End Date: {new Date(poll.endDate).toLocaleString()}</p>
                  <p>Finalized: {poll.finalized ? 'Yes' : 'No'}</p>
                  <div>
                    <h4>Choices:</h4>
                    <ul>
                      {pollDetails[poll.id]?.choices.map((choice, index) => (
                        <li key={choice.id}>
                          <label>
                            <input
                              type='radio'
                              name={`poll-${poll.id}`}
                              value={choice.id}
                              onChange={() => setChoiceId(choice.id)}
                              disabled={poll.finalized}
                            />
                            {choice.title}
                          </label>
                          {poll.finalized && voteResults[poll.id]
                            ? ` - Votes: ${
                                voteResults[poll.id][index] /
                                  1000000000000000000n || 0
                              }`
                            : ''}
                        </li>
                      ))}
                    </ul>
                  </div>
                  {!poll.finalized && (
                    <div>
                      <input
                        type='number'
                        min='1'
                        value={comoAmount}
                        onChange={e => setComoAmount(parseInt(e.target.value))}
                        placeholder='COMO amount'
                      />
                      <button
                        onClick={() => handleVote(poll.id)}
                        disabled={isVoting || !choiceId}
                      >
                        {isVoting ? 'Voting...' : 'Vote'}
                      </button>
                      {voteError && <p style={{ color: 'red' }}>{voteError}</p>}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <div>No gravity found.</div>
      )}
    </div>
  );
}

export default GravityDetail;
