import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  ContextType,
} from 'react';
import { cosmoClient, Credentials, Profile } from './cosmo';
import { RamperWalletSecrets } from './ramper';
import { polarisClient } from './polaris';

type Account = {
  credentials: Credentials;
  profile: Profile;
  ramperWalletSecrets: RamperWalletSecrets;
  socialLoginUserId: string;
};

let AccountsContext = createContext<{
  accounts: Account[];
  isLoading: boolean;
  addAccount: (account: Account) => void;
  removeAccount: (profileId: number) => void;
  setCurrentAccount: (profileId: number) => void;
}>(null!);

export function useAccounts(): ContextType<typeof AccountsContext> {
  return useContext(AccountsContext);
}

export function useCurrentAccount(): Account | null {
  let { accounts } = useAccounts();
  if (accounts.length) {
    return accounts[0];
  } else {
    return null;
  }
}

export function useProfile() {
  let currentAccount = useCurrentAccount();
  return currentAccount?.profile;
}

export function CredentialsProvider({ children }: { children: ReactNode }) {
  let [accounts, setAccounts] = useState<Account[]>([]);
  let [isLoading, setIsLoading] = useState(false);

  // Refresh the current account credentials on mount.
  useEffect(() => {
    let isCancelled = false;
    (async () => {
      setIsLoading(true);
      let accounts = JSON.parse(
        localStorage.getItem('accounts') ?? '[]',
      ) as Account[];
      while (accounts.length) {
        let currentAccount = accounts[0];
        try {
          // Force log out if this is still undefined
          if (!currentAccount.socialLoginUserId) {
            accounts = accounts.slice(1);
            continue;
          }
          currentAccount.credentials = await cosmoClient.refreshCredentials(
            currentAccount.credentials.refreshToken,
          );
        } catch (error) {
          if (error instanceof Error && error.message === 'unauthorized') {
            accounts = accounts.slice(1);
            continue;
          }
        }
        try {
          await polarisClient.signIn(currentAccount.socialLoginUserId);
        } catch (error) {
          console.error(error);
        }
        break;
      }
      if (!isCancelled) {
        setIsLoading(false);
        setAccounts(accounts);
        if (accounts.length) {
          let currentAccount = accounts[0];
          cosmoClient.credentials = currentAccount.credentials;
          polarisClient.token = currentAccount.socialLoginUserId;
          localStorage.setItem('accounts', JSON.stringify(accounts));
        } else {
          cosmoClient.credentials = null;
          polarisClient.token = null;
          localStorage.removeItem('accounts');
        }
      }
    })();
    return () => void (isCancelled = true);
  }, []);

  function addAccount(account: Account) {
    // Since this is a new account, assume that the credentials are fresh.
    cosmoClient.credentials = account.credentials;
    polarisClient.token = account.socialLoginUserId;
    let newAccounts = [
      account,
      ...accounts.filter(
        originalAccount => originalAccount.profile.id !== account.profile.id,
      ),
    ];
    setAccounts(newAccounts);
    localStorage.setItem('accounts', JSON.stringify(newAccounts));
  }

  async function removeAccount(profileId: number) {
    let newAccounts = accounts.filter(
      account => account.profile.id !== profileId,
    );
    while (newAccounts.length) {
      let currentAccount = newAccounts[0];
      try {
        currentAccount.credentials = await cosmoClient.refreshCredentials(
          currentAccount.credentials.refreshToken,
        );
      } catch (error) {
        if (error instanceof Error && error.message === 'unauthorized') {
          newAccounts = newAccounts.slice(1);
          continue;
        }
      }
      break;
    }
    setAccounts(newAccounts);
    if (newAccounts.length) {
      let currentAccount = newAccounts[0];
      cosmoClient.credentials = currentAccount.credentials;
      polarisClient.token = currentAccount.socialLoginUserId;
      localStorage.setItem('accounts', JSON.stringify(newAccounts));
    } else {
      cosmoClient.credentials = null;
      polarisClient.token = null;
      localStorage.removeItem('accounts');
    }
  }

  async function setCurrentAccount(profileId: number) {
    // Switching the current account requires refreshing credentials.
    if (!accounts.find(account => account.profile.id === profileId)) {
      return;
    }
    let newAccounts = [
      accounts.find(account => account.profile.id === profileId)!,
      ...accounts.filter(account => account.profile.id !== profileId),
    ];
    while (newAccounts.length) {
      let currentAccount = newAccounts[0];
      try {
        currentAccount.credentials = await cosmoClient.refreshCredentials(
          currentAccount.credentials.refreshToken,
        );
      } catch (error) {
        if (error instanceof Error && error.message === 'unauthorized') {
          newAccounts = newAccounts.slice(1);
          continue;
        }
      }
      break;
    }
    setAccounts(newAccounts);
    if (newAccounts.length) {
      let currentAccount = newAccounts[0];
      cosmoClient.credentials = currentAccount.credentials;
      polarisClient.token = currentAccount.socialLoginUserId;
      localStorage.setItem('accounts', JSON.stringify(newAccounts));
    } else {
      cosmoClient.credentials = null;
      polarisClient.token = null;
      localStorage.removeItem('accounts');
    }
  }

  return (
    <AccountsContext.Provider
      value={{
        isLoading,
        accounts,
        addAccount,
        removeAccount,
        setCurrentAccount,
      }}
    >
      {children}
    </AccountsContext.Provider>
  );
}
