import {
  Autocomplete,
  AutocompleteItem,
  AutocompleteProps,
  User,
} from '@nextui-org/react';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useAsyncList } from 'react-stately';
import { useDebounce } from 'use-debounce';
import { CosmoClient, SearchedProfile, cosmoClient } from '../cosmo';

export function CosmoNicknameAutocomplete({
  nickname,
  setNickname,
  ...props
}: {
  nickname: string;
  setNickname: Dispatch<SetStateAction<string>>;
} & Omit<AutocompleteProps, 'children'>) {
  let list = useAsyncList<SearchedProfile>({
    async load({ filterText }) {
      if (!filterText || filterText.length < 4) {
        return { items: [] };
      }
      if (!cosmoClient.credentials) {
        let proxyClient = new CosmoClient(
          'https://proxy.goranmoomin.dev/cosmo/',
        );
        let { results: items } = await proxyClient.searchUsers(filterText);
        return { items };
      } else {
        let { results: items } = await cosmoClient.searchUsers(filterText);
        return { items };
      }
    },
  });
  let [listFilterText] = useDebounce(nickname, 100);
  useEffect(() => list.setFilterText(listFilterText), [listFilterText]);
  return (
    <Autocomplete
      isClearable
      allowsCustomValue
      isLoading={list.isLoading}
      items={list.items}
      inputValue={nickname}
      onInputChange={setNickname}
      inputProps={{
        classNames: {
          input: 'text-base placeholder:text-small',
        },
      }}
      popoverProps={{ placement: 'top' }}
      {...props}
    >
      {list.items.map(item => (
        <AutocompleteItem key={item.nickname} textValue={item.nickname}>
          <User
            name={item.nickname}
            description={item.address}
            avatarProps={{
              src:
                'profile' in item && item.profile.length
                  ? item.profile[0].image.original
                  : 'https://static.cosmo.fans/uploads/images/img_profile_gallag@3x.png',
            }}
          />
        </AutocompleteItem>
      ))}
    </Autocomplete>
  );
}
